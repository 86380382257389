import * as authentication from "./authentication";
import * as console_ from "./console";
import * as serviceWorker from "./serviceWorker";
import { Elm } from "./Main.elm";
import translations from "../translations/en.json";

const environment =
  window.origin === "https://fysiweb.app"
    ? "production"
    : window.origin === "https://dev.fysiweb.app"
      ? "development"
      : "local";

const seed = (() => {
  const ints = Array.from(crypto.getRandomValues(new Uint32Array(5)));
  return [ints[0], ints.slice(1)];
})();

(async () => {
  const authData = await authentication.getAuthData();
  var elmApp = Elm.Main.init({
    flags: {
      environment,
      screen: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      seed,
      authData,
      translations,
    },
  });

  authentication.init(elmApp);
  console_.init(elmApp);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
